import { Students } from "classes/models/students/student.model";
import { StudentsService } from "~/classes/models/students/students.service";

export const useStudentsClassroomsData = (userId: string) =>
  defineStore(`/users/${userId}/students/classrooms/data`, () => {
    const appUserDataStore = useAppUserData();
    const { studentPermissions } = storeToRefs(appUserDataStore);

    const activeStudentId = ref<string>();
    const students = ref<Students>([]);

    const initializeStudentsClassroomData = async () => {
      for (const studentId of studentPermissions.value) {
        const studentClassroomDataStore =
          useStudentClassroomDataStore(studentId);
        await studentClassroomDataStore.initializeStudentClassroomData();
      }

      if (studentPermissions.value.length == 0) {
        throw new Error("No students found");
      }

      activeStudentId.value = studentPermissions.value[0];

      // get each student
      for (const studentId of studentPermissions.value) {
        const student = await StudentsService.get(studentId);
        students.value.push(student);
      }

      students.value = students.value.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    };

    const changeActiveStudent = (studentId: string) => {
      activeStudentId.value = studentId;
    };

    return {
      initializeStudentsClassroomData,
      changeActiveStudent,
      activeStudentId,
      students,
    };
  });
